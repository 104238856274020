<template>
    <div class="navmb">
        <div class="flex flexa">
        <el-icon color="#FF6A00" size="20" class="astyle bgshadow" @click="$router.back()"><Back /></el-icon> &nbsp; &nbsp;
        <span class="">手机换绑</span>
        </div>
    </div>
    <p class="h20"></p>
    <div class="regmid bgf ">
        <div class="midhead borbot font14 col9">
            <p class="col24">01.身份验证</p>
            <div class="pad14">
                <img v-if="step>=2"  :src="utils.loadImg('line-a.png')" alt="">
                <img v-else :src="utils.loadImg('line.png')" alt="">
            </div>
            <p :class="step>=2?'col24':''">02.重新绑定</p>
            <div class="pad14">
                <img v-if="step>=3"  :src="utils.loadImg('line-a.png')" alt="">
                <img v-else :src="utils.loadImg('line.png')" alt="">
            </div>
            <p :class="step>=3?'col24':''">03.完成</p>
        </div>

        <div v-if="step==1" class="step2">
            <ul>
                <li>
                    <label for="">已绑定手机号：</label>
                    <p>{{ utils.hidephone(info.mobile) }}</p>
                </li>
                <li>
                    <label for="">手机验证码：</label>
                    <el-input v-model="info.authCode" class="w227" placeholder="输入验证码" size="large"/>
                    <button @click="handleGetSms(info.mobile)" class="btn1">{{yzmSend ? '重新发送('+ time+'s)' : '获取手机验证码'}}</button>
                </li>
                <li>
                    <label for=""></label>
                    <el-button @click="next1" type="primary" class="w125" size="large" >
                        下一步
                    </el-button>
                </li>
            </ul>

        </div>

        <div v-if="step==2" class="step2">
            <ul>
                <li>
                    <label for="">请输入新手机号：</label>
                    <el-input v-model="param2.mobile" class="w400" placeholder="输入新手机号" size="large"/>
                </li>
                <li>
                    <label for="">手机验证码：</label>
                    <el-input v-model="param2.authCode" class="w227" placeholder="输入验证码" size="large"/>
                    <button @click="handleGetSms(param2.mobile)" class="btn1">{{yzmSend ? '重新发送('+ time+'s)' : '获取手机验证码'}}</button>
                </li>
                <li>
                    <label for=""></label>
                    <el-button @click="bindphone" type="primary" class="w125" size="large" >
                        立即绑定
                    </el-button>
                </li>
            </ul>
        </div>

        <div v-if="step==3" class="step4">
            <div class="flex flexa flexc padb40 padt20">
                <img :src="utils.loadImg('success.png')" alt="" width="43" height="43" srcset="">
               <p class="col24 font22 padl20 ">恭喜您，换绑成功！</p>
            </div>
            <p class="h20"></p>
            
            <el-button @click="$router.push('/account')" type="primary" class="w172" size="large" >
                返回设置中心
            </el-button>
        </div>
    </div>
</template>
<script>
import { changeMobile, changeNewMobile, sendVerificationCode } from "@/utils/api1.js"
import { Back } from '@element-plus/icons-vue'
export default {
    components: {
        Back
    },
    data(){
        return {
            info: {
                mobile: this.$route.query.mobile,
                authCode: '',
            },
            param2:{
                mobile: '',
                authCode: '',
            },
            step: 1,
            tab:1,
            yzm: '',            
            time:60,
            yzmSend: false,
            timmer: null,
            deadline: Date.now() + 1000 * 10 ,
        }
    },
    mounted(){
        
    },
    methods: {
        next1(){
            if (!this.info.authCode) {
                return this.$message.warning('请输入验证码！')
            }
            changeMobile(this.info).then(res=>{
                console.log(res);
                if(res){
                    this.step = 2;
                    this.time = 60;
                    this.yzmSend = false;
                }
            })
        },

        bindphone(){
            if (!/^1(3|4|5|6|7|8|9)\d{9}$/.test(this.param2.mobile)){
               return this.$message.warning('请输入正确手机号！')
            } else if (!this.param2.authCode) {
                return this.$message.warning('请输入验证码！')
            }
            this.param2.oldMobile = this.info.mobile;
            console.log(this.param2)
            changeNewMobile(this.param2).then(res=>{
                console.log(res);
                if(res){
                    this.step = 3;
                }
            })
        },
       
        handleGetSms(phone) {
            let that = this;
            if (that.yzmSend) return;
            
            sendVerificationCode({mobile:phone}).then(res=>{
                console.log(res);
                if(res){
                    that.$message.success("验证码发送成功！");
                }
            })
            
            if(!that.yzmSend){
                that.yzmSend = true;
                that.timmer = setInterval(function () {
                    if (that.time == 1) {
                        that.time = 60;
                        that.yzmSend = false;
                        clearInterval(that.timmer);
                        return;
                    }
                    that.time--;
                }, 1000);
            }
        },

        onFinish(){
            console.log('完成');
            this.$router.push('/login');
        }
    },
}
</script>
<style lang="scss" scoped>
.w227{ width: 227px;}
.w172{ width: 172px;}
.w125{ width: 125px;}
.padt3{ padding-top: 3px;}
.w400{ width: 400px;}
.mw{
    min-width: 1090px;
}
.img1{
    width: 96px;
    height: 40px;
    border-radius: 2px;
    border: 1px solid #E6E8EB;
    margin-left: 10px;
}
.step4{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 20px;
}
.step2{
    padding-top: 60px;
    display: flex;
    justify-content: center;
    >ul{
        color: #3F4651;
        font-size: 12px;
        >li{
            padding-bottom: 36px;
            display: flex;
            align-items: center;
            >label{
                width: 96px;
                min-width: 96px;

            }
        }
    }
}
.step3{
    padding-top: 45px;
    width: 320px;
    margin:0 auto;
}
.regtop{
    background: #34383F;
    height: 50px;
    padding: 0 25px;
    position: fixed;
    top: 0;
    width: 100%;
    min-width: 1090px;
}

.regmid{
    box-sizing: border-box;
    padding: 0 64px 57px;
    min-height: calc(100vh - 140px);
}
.wmid{
    width: 320px;
    margin: 0 auto;
}
.midhead{
    line-height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    
}
.btn1{
    margin-left: 14px;
    border-radius: 2px;
    color: #71767E;
    font-size: 12px;
    width: 155px;
    min-width: 155px;
    height: 40px;
}
.tabs{
    display: flex;
    justify-content: space-around;
    font-size: 18px;
    color: #242424;
    li>p{
        width: 80px;
        height: 4px;
        background: #fff;
        margin: 16px auto;
    }
    li{
        cursor: pointer;
    }
    .act{
        color: #FF6A00;
        >p{
            background: #FF6A00;
        }
    }
}
</style>